export const menuItems = [
  {
    path: "/",
    text: "Početna",
  },
  {
    path: "/svi-apartmani",
    text: "Naši Apartmani",
  },
  {
    path: "/contact",
    text: "Kontakt",
  },
]
